import React from 'react';
import './App.css';

import A from "../src/img/A.png";
import B from "../src/img/B.png";
import C from "../src/img/C.png";

function App() {

  // Correctly structured array of image elements
  const photos = [
    <img src={A} alt="A" />,
    <img src={B} alt="B" />,
    <img src={C} alt="C" />
  ];

  return (
    <div className="App">
      <header>
        <h1>Our Sticker Company</h1>

      </header>


      <div className="photo-row">
        {photos}
      </div>

    </div >
  );
}

export default App;
